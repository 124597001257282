import axios from 'axios'
import globalData from './data'

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  baseURL: globalData.httpUrl,
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log('请求被拦截', error)
  }
)

service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log('请求被拦截', error)
  }
)

export default service
