import request from '../util/request'

// 评分数据
export const getProject = query => {
  return request({
    url: 'users/score_result',
    method: 'get',
    params: query
  })
}

// 投票数据
export const getVoteResult = query => {
  return request({
    url: 'users/vote_result',
    method: 'get',
    params: query
  })
}
