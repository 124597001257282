<template>
  <div class="box" :style="'height:' + height + 'rem'">
    <img src="../assets/images/bg@2x.png" class="imgsize" />
    <h1 class="title">{{ scoretitle }}</h1>
    <div class="hidden-scroll-bar">
      <div class="nav">
        <div
          class="nav-list"
          :class="item.number % 2 == 1 ? 'bgborder' : ''"
          v-for="item in scoredata"
          :key="item.id"
        >
          <div class="serialnumber">{{ item.number }}</div>
          <div class="number" style="font-size: 0.1rem;width: 0.2981rem;white-space: nowrap;">{{ item.code }}</div>
          <div class="name">{{ item.title }}</div>
          <div class="scorebox">
            <div class="score" style="width:4.6875rem" v-if="item.number == 1">
              <span
                class="score1 center"
                :style="'width:' + (item.score / (item.scores * 2)) * 100 + '%'"
              >
                <span> 初审分：{{ item.score }} </span>
              </span>
              <span
                class="score2 center"
                :style="'width:' + (item.finally / (item.scores * 2)) * 100 + '%'"
              >
                <span> 终审分：{{ item.finally }} </span>
              </span>
            </div>
            <div
              class="score"
              :style="
                'width:' +
                  ((item.score + item.finally) / Maximum) * 4.6875 +
                  'rem'
              "
              v-if="item.number != 1"
            >
              <span
                class="score1 center"
                :style="'width:' + (item.score / Maximum) * 4.6875 + 'rem'"
              >
                <span> 初审分：{{ item.score }} </span>
              </span>
              <!--终审分-->
              <span
                class="score2 center"
                :style="'width:' + (item.finally / Maximum) * 4.6875 + 'rem'"
              >
                <span> 终审分：{{ item.finally }} </span>
              </span>
            </div>
            <div class="textsize" style="font-size: 0.12rem;line-height: 0.1051rem;white-space: nowrap;">最终得分：{{ item.scores }}</div>
          </div>
        </div>

    </div>

    </div>
    <div class="prompt" v-show="prompt">
      <h1>暂未有评分结果</h1>
    </div>
  </div>
</template>

<style lang="less" scoped>
/*父盒子样式*/
.box {
  position: relative;
  width: 10rem;
  height: 4.875rem; //78.5px
  // height: 5.625rem;//90px
  overflow: hidden;
}
/*背景图样式*/
.imgsize {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
/*标题样式样式*/
.title {
    position: absolute;
    background-clip: text;
    -webkit-background-clip: text;
    top: 0.1875rem;
    /* left: 4.03125rem; */
    font-size: 0.1875rem;
    background-image: linear-gradient(to bottom, #f5e3c4, #bf9c61);
    color: transparent;
    width: 100%;
    text-align: center;
}
.hidden-scroll-bar{
  position: absolute;
  top: 0.71875rem; //11.5px
  // font-size: 0.09375rem; //1.5px
  font-size: 0.12rem; //1.5px
  left: 0.78125rem; //12.5px
  right: 0.75625rem; //12.1px
  overflow-y: scroll;
  height: 3.54rem;
  overflow: hidden;
  width: 8.383rem;
}

/*nav样式*/
.nav {
  width: 8.592rem;
  // position: absolute;
  top: 0.71875rem; //11.5px
  // font-size: 0.09375rem; //1.5px
  font-size: 0.12rem; //1.5px
  left: 0.78125rem; //12.5px
  right: 0.75625rem; //12.1px
  overflow-y: scroll;
  height: 3.54rem;
}
/*nav-list边框、背景色样式*/
.bgborder {
  background: RGBA(30, 45, 75, 1);
  border-radius: 0.01875rem; //0.3px
}
/*nav-list样式*/
.nav-list {
  color: white;
  display: flex;
  flex: 1;
  // height: 0.20625rem; //3.3px
  // line-height: 0.20625rem; //3.3px
  height: 0.4123rem;
  // line-height: 0.4123rem;
  align-items: center;

}
/*序号样式*/
.serialnumber {
  margin-left: 0.1875rem; //3px
  margin-right: 0.1875rem; //3px
  width: 0.13137rem
}
/*编号样式*/
.number {
  margin-right: 0.1875rem; //3px
}
/*作品样式*/
.name {
  width: 1.53125rem; //24.5px
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*分数条样式*/
.scorebox {
  display: flex;
  // padding-top: 0.05rem; //0.8px
  padding-left: 0.125rem; //2px
  align-items: center;
}
.score {
  display: flex;
  width: 5.1875rem; //83px
  // height: 0.10625rem; //1.7px
  height: 0.23rem; //1.7px
  line-height: 0.23rem; //1.7px
  overflow: hidden;
}
.score1 {
  padding: 0 0.03125rem;
  position: relative;
  display: inline-block;
  background: RGBA(55, 120, 255, 1);
  // font-size: 0.00625rem; //0.1px
  font-size: 0.015;
  overflow: hidden;
}
.score2 {
  padding: 0 0.03125rem;
  position: relative;
  display: inline-block;
  // font-size: 0.00625rem; //0.1px
  font-size: 0.12rem; //0.1px
  background-color: rgba(191, 156, 97, 1);
  overflow: hidden;
}
.center {
  text-align: center;
}
/*分数字体样式*/
.textsize {
  // margin-top: -0.05rem;
  // margin-left: -0.125rem;
  margin-left: 0.125rem; //2px
  // font-size: 0.00625rem; //0.1px
  font-size: 0.0125rem; //0.1px
  line-height: 0.23rem; //1.7px;
}
.location {
  position: absolute;
  right: 0.01875rem; //0.3px
}
.locationleft {
  position: absolute;
  left: 0.01875rem; //0.3px
}
.examination {
  margin-left: -0.0625rem; //-1px
}
.prompt{
  h1{
    position: absolute;
    left: 50%;
    top: 50%;
    color: white;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    font-size: 0.12631rem;
  }
}
</style>

<script>
import { getProject } from '../api/index'
export default {
  name: 'Home',
  data () {
    return {
      scoredata: [],
      scoretitle: '',
      Maximum: '',
      height: 4.875,
      isShow: '',
      prompt: false
    }
  },
  methods: {
    getDate () {
      getProject().then(res => {
        if (res.data.data.arr && res.data.data.arr.length > 0) {
          this.scoredata = res.data.data.arr
          this.scoretitle = res.data.data.type_name
          this.Maximum = res.data.data.arr[0].scores * 2
          this.prompt = false
          console.log('this.scoredata2', this.scoredata)
          console.log('this.scoretitle', this.scoretitle)
        } else {
          this.prompt = true
        }
      })
    },
    keyDown () {
      document.onkeydown = e => {
        this.isShow = true
        if (e.key === 'F11' && this.isShow) {
          this.height = 5.625
          this.isShow = false
        } else {
          this.height = 4.875
        }
        console.log(e)
      }
    }
  },
  created () {
    document.title = '评分大屏'
  },

  mounted () {
    this.getDate()
    this.keyDown()
  }
}
</script>
