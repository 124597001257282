<template>
  <router-view/>
</template>

<style lang="less">
html,body{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
