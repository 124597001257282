import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import './assets/css/style.css'
import 'amfe-flexible/index.js'

const app = createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
app.echarts = echarts
