<template>
  <div class="box" :style="'height:' + height + 'rem'" style="position: relative;">
    <img src="../assets/images/bg@2x.png" class="imgsize" />
    <h1 class="title">{{ votetitle }}</h1>
    <div class="bars-content">
      <div class="bar-content" v-for="(item) in votedata" :key="item.id" :style="{width: `${width* 100}%`, height: `${item.height * 100}%`}">
        <!-- {{item}} -->
        <!-- <p>width: {{width}}</p> -->
        <div class="vote-count">
          {{item.vote}}票
        </div>
        <div class="vote-bar"></div>
        <div class="vote-name">
          {{item.title}}
        </div>
      </div>
    </div>
<!--    <div class="nav">
      <div class="vote" v-if="votedata.length <= 2">
        <div
          :class="index === 0 ? 'nav-vote1' : 'nav-vote2'"
          v-for="(item, index) in votedata"
          :key="item.id"
        >
          <div
            :style="'height:' + (3.25 - (item.vote / Maximum) * 3.25) + 'rem'"
          ></div>
          <h1 :class="index === 0 ? 'votecolor1' : 'votecolor2'">
            {{ item.vote }}票
          </h1>
          <div
            :class="index === 0 ? 'NumberOfVotes1' : 'NumberOfVotes2'"
            :style="'height:' + (item.vote / Maximum) * 3.25 + 'rem'"
          ></div>
          <div class="textcolor" :class="item.vote === 0 ? 'textcolor2' : ''">
            {{ item.code }} {{ item.title }}
          </div>
        </div>
      </div>
      <div class="vote" v-if="votedata.length > 2">
        <div
          :class="
            index === 0
              ? 'nav-votetwo1'
              : index === 1
              ? 'nav-votetwo2'
              : 'nav-votetwo3'
          "
          v-for="(item, index) in votedata"
          :key="item.id"
        >
          <div
            :style="'height:' + (3.25 - (item.vote / Maximum) * 3.25) + 'rem'"
          ></div>
          <h1
            :class="
              index === 0
                ? 'votecolortwo1'
                : index === 1
                ? 'votecolortwo2'
                : 'votecolortwo3'
            "
          >
            {{ item.vote }}票
          </h1>
          <div
            :class="
              index === 0
                ? 'NumberOfVotestwo1'
                : index === 1
                ? 'NumberOfVotestwo2'
                : 'NumberOfVotestwo3'
            "
            :style="'height:' + (item.vote / Maximum) * 3.25 + 'rem'"
          ></div>
          <div class="textcolor" :class="item.vote === 0 ? 'textcolor2' : ''">
            {{ item.code }} {{ item.title }}
          </div>
        </div>
      </div>
    </div>
      -->
    <div class="prompt" v-show="prompt">
      <h1>暂未有投票结果</h1>
    </div>
  </div>
</template>

<script>
import { getVoteResult } from '../api/index'
export default {
  name: 'specificScore',
  data () {
    return {
      votedata: [],
      votetitle: '',
      Maximum: '',
      // height: 4.875,
      prompt: false,
      width: 0
    }
  },
  methods: {
    getDate () {
      getVoteResult().then(res => {
        console.log(res)
        if (res.data.data.arr && res.data.data.arr.length > 0) {
          // console.log(11)
          this.Maximum = res.data.data.type_count
          this.votedata = res.data.data.arr.map((item) => {
            item.height = item.vote / (res.data.data.type_count)
            console.log(item)
            return item
          })
          this.votetitle = res.data.data.type_name
          this.prompt = false
          this.width = 0.7 / (res.data.data.arr.length)
          console.log(this.width)
          // console.log('this.Maximum', this.Maximum)
          // console.log('this.votedata', this.votedata)
          // console.log('this.votetitle', this.votetitle)
        } else {
          this.votedata = []
          this.votetitle = ''
          this.Maximum = ''
          this.prompt = true
        }
      })
    },
    keyDown () {
      document.onkeydown = e => {
        console.log(e)
        if (e.code === 'F11') {
          this.height = 5.625
        } else {
          this.height = 4.875
        }
      }
    }

  },
  mounted () {
    this.getDate()
    this.keyDown()
  },
  created () {
    document.title = '投票大屏'

    window.setInterval(() => {
      setTimeout(() => {
        this.getDate()
      }, 0)
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
/*父盒子样式*/
.box {
  position: relative;
  width: 10rem;
  height: 4.875rem; //78.5px
  overflow: hidden;
}
/*背景图样式*/
.imgsize {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
/*标题样式样式*/
.title {
    position: absolute;
    background-clip: text;
    -webkit-background-clip: text;
    top: 0.1875rem;
    /* left: 4.03125rem; */
    font-size: 0.1875rem;
    background-image: linear-gradient(to bottom, #f5e3c4, #bf9c61);
    color: transparent;
    width: 100%;
    text-align: center;
    }

//投票样式
.nav {
  top: 0.75rem; //12px
  width: 9.5rem; //152px
  left: 0.25rem; //4px
  height: 4.15rem; //66.4px
  position: absolute;
  display: flex;
}

.vote {
  position: relative;
  width: 100%;
}

.textcolor {
  font-size: 0.09375rem; //1.5px
  margin-top: 0.125rem; //2px
  color: white;
}

.textcolor2 {
  margin-top: 0.1875rem; //2px
}

//第一种情况样式
.nav-vote1,
.nav-vote2 {
  position: absolute;
  text-align: center;
  width: 1.25rem; //20px
}
.nav-vote1 {
  left: 2.4375rem; //39px
}
.nav-vote2 {
  right: 2.4375rem; //39px
}
.votecolor1,
.votecolor2 {
  font-size: 0.1875rem; //3px
  margin-bottom: 0.0625rem; //1px
}
.votecolor1 {
  color: rgba(55, 120, 255, 1);
}
.votecolor2 {
  color: rgba(191, 156, 97, 1);
}
.NumberOfVotes1,
.NumberOfVotes2 {
  margin-left: 0.40625rem; //6.5px
  width: 0.4375rem; //7px
  height: 3.25rem; //52px
}
.NumberOfVotes1 {
  background: rgba(55, 120, 255, 1);
}
.NumberOfVotes2 {
  background: rgba(191, 156, 97, 1);
}

//第二种情况样式

//投票盒子样式
.nav-votetwo1,
.nav-votetwo2,
.nav-votetwo3 {
  position: absolute;
  text-align: center;
  width: 1.25rem; //20px
}
.nav-votetwo1 {
  left: 1.4375rem; //23px
}
.nav-votetwo2 {
  right: 1.4375rem; //23px
}
.nav-votetwo3 {
  left: 4.1875rem; //67px
}

//票数样式
.votecolortwo1,
.votecolortwo2,
.votecolortwo3 {
  font-size: 0.1875rem; //3px
  margin-bottom: 0.0625rem; //1px
  font-weight: 500;
}
.votecolortwo1 {
  color: #bf9c61;
}
.votecolortwo2 {
  color: rgba(191, 156, 97, 1);
}
.votecolortwo3 {
  color: rgba(191, 156, 97, 1);
}

//票数显示样式
.NumberOfVotestwo1,
.NumberOfVotestwo2,
.NumberOfVotestwo3 {
  margin-left: 0.40625rem; //6.5px
  width: 0.4375rem; //7px
  height: 3.25rem; //52px
}
.NumberOfVotestwo1 {
  background: #bf9c61;
}
.NumberOfVotestwo2 {
  background: rgba(191, 156, 97, 1);
}
.NumberOfVotestwo3 {
  background: rgba(191, 156, 97, 1);
}
.prompt{
  h1{
    position: absolute;
    left: 50%;
    top: 50%;
    color: white;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }
}
.bars-content{
  height: 60%;
  width: 90%;
  // background-color: red;
  position: absolute;
  // top: 50%;
  bottom: 14%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: flex-end;

  .bar-content{
    // background-color: green;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .vote-count{
      position: absolute;
      top: -20px;
      color: #bf9c61;
      font-size: 0.1875rem;
    }
    .vote-bar{
      height: 100%;
      width: 35%;
      background-color: #bf9c61;
    }
    .vote-name{
      position: absolute;
      bottom: 0;
      color: #FFFFFF;
      transform: translateY(103%);
      font-size: 0.1165rem;
      text-align: center;
    }
  }
}

</style>
